@import "../../common/variables.scss";

.services {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .card {
        text-align: center;
        // flex: 1 1 1rem;
        width: 25%;
        display: flex;
        flex-wrap: wrap !important;
        flex-direction: column;
        align-items: center;
        align-self: normal;
        border-radius: 0px;
        border: none;
        background-color: $primary_light;
        transition: .5s;

        .card-body {
            text-align: center;

            .card-title {
                margin: 20px 0px;
                font-weight: 600;
            }

            p {
                margin-top: auto;
                margin: 20px 0px;
            }

            .service_icon {
                margin: 2rem 0rem;
                border-radius: 30%;
                font-size: 5rem;
                background-color: $primary;
                padding: 10px;
                border-radius: 50%;
                border: 2px solid $secondary;
                transition: .5s;
            }

            &:hover {
                background-color: $primary;
                transition: .5s;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                transform: scale3d(1.006, 1.006, 1);

                .service_icon {
                    background-color: white;
                    color: $primary !important;
                    transition: .5s;
                }

                .card-title,
                p {
                    color: white;
                }
            }
        }
    }

    @media (max-width:992px) {
        .card {
            width: 50%;
        }
    }
    @media (max-width:425px) {
        .card {
            width: 100%;
        }
    }
}