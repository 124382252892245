@import "../../common/variables.scss";

.founder-box-container{
    display: flex;
    align-items: center;
    margin: 40px 0;
    @media (min-width:992px) {
        margin: 4rem 0rem;
        margin-bottom: 6rem !important;
    }

    .image-box-container{
        width: 400px;
        height: 500px;
        position: relative;   
        overflow: hidden; 
        
        :nth-child(1){
            width: 300px;
            height: 90%;
            background-color: $primary;
            position: absolute;
            z-index: -1 !important;
        }

        img{
            width: 100% !important;
            height: 100%;
            object-fit: cover;
            margin-top: 10px;
            margin-left: 10px;
        }        
    }

    .founder-details{
        background-color: $primary;
        position: absolute;
        z-index: 2 !important;
        right: -3%;
        bottom: -5%;
        padding: 10px;
        border: 2px solid #93BF47;
        color: white;

        :nth-child(2){
            font-weight: 400 !important;
        }
    }

    @media (max-width:992px) {
        flex-direction: column;
        align-items: start !important;
    }

    @media (max-width:470px) {
        .image-box-container{
            width: 100% !important;
            height: 400px;
        }
    }

    @media (max-width:375px) {
        .image-box-container{
            width: 260px;
            height: 350px;
        }
    }

    .founder-content{
        .founder-title{
            color: $primary;
            border-left: 6px solid $primary;
            margin: 20px 0px;

            h1{
                font-weight: 700 !important;
                margin-left: 10px;
            }
        }

        .founder-content-inner{
            font-style: italic;

            p{
                font-size: 21px;
                letter-spacing: 1px;   
            }
    
            .founder-bio{
                display: flex;
                align-items: center;
                padding: 5px;
                padding-left: 20px;
    
                :nth-child(1){
                    width: 30px;
                }
    
                :nth-child(2){
                    font-size: 22px;
                    font-weight: 700;
                    padding: 0px 20px;
                    color: black;
                }
            }
        }
        @media (max-width:768px) {
            .founder-content-inner{
                p{
                    font-size: 16px;
                }

                .founder-bio{
                    :nth-child(1){
                        width: 25px;
                    }
        
                    :nth-child(2){
                        font-size: 18px;
                    }
                }
            }
        }
    }
}