.card {
    position: relative;
    height: 280px;
    margin-bottom: 20px;
    display: block;
}

.cardImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.cardBody {
    background-color: white;
    padding: 20px;
    position: absolute;
    z-index: 1;
    right: 0;
    width: 90%;
    bottom: 0;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: right;
}

.cardTitle {
    color: var(--primary-color);
    font-size: 16px;
    text-align: right;
    width: 100%;
    font-weight: 500;
}

.cardContent {
    margin: 0 !important;
    text-align: right;
    font-size: 14px;
    color: black;
}