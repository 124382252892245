@import "../../common/variables.scss";

.nav-contact {
    padding: 5px 0px;
    background-color: $primary;

    .nav-contact {
        display: flex;
        align-items: center;
        justify-content: right;
        gap: 10px;

        .contact {
            border-right: 1px solid white;
            padding: 0;
            text-decoration: none;
        }

        .nav-contact-icons {
            img {
                border: 2px solid white;
                padding: 5px;
                border-radius: 5px;
                margin-left: 10px;
                width: 35px;
            }
        }

        img {
            padding: 5px 0px;
        }

        span {
            color: white;
            font-size: 18px;
            margin: 0px 10px;
        }

        @media (max-width:992px) {
            .contact {
                img {
                    width: 22px;
                }

                span {
                    font-size: 15px;
                }
            }

            .nav-contact-icons {
                img {
                    width: 30px;
                }
            }
        }

        @media (max-width:768px) {
            flex-direction: column;

            .contact {
                border: none;
            }
        }

    }
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .brand-logo {
        width: 250px;
    }

    .nav-links {
        margin: 15px;
        transition: width 0.5s;
        color: black !important;
        font-size: 17px;
        border: none;
        outline: none;

        &:focus {
            color: $primary !important;
        }
    }

    .nav-link::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background: $primary;
        transition: width .3s;
    }

    .nav-link:hover::after {
        width: 100%;
        transition: width .3s;
    }

    .navbar-button {
        background-color: $primary;
        color: white;
        border: none;
        display: flex;
        align-items: center;
        padding: 8px 20px;
        transition: .2s;

        img {
            width: 33px;
        }

        span {
            font-weight: 500;
            font-size: 16px;
        }

        &:hover {
            background-color: $secondary;
            transition: .2s;
        }

        @media (max-width:1200px) {
            padding: 20px;
        }
    }

    @media (max-width:1400px) {
        .nav-links {
            font-size: 15px !important;
        }
    }

    @media (max-width:500px) {
        .brand-logo {
            width: 200px;
        }
    }

    @media (max-width:375px) {
        .brand-logo {
            width: 180px;
        }
    }
}

.navbar-toggler {
    border: none !important;

    .navbar-toggler-icon {
        background-image: url("../../assets/images/menu-icon.jpg") !important;
        width: 50px !important;
        background-color: $primary;
    }

    @media (max-width:375px) {
        .navbar-toggler-icon {
            width: 30px !important;
        }
    }

}

.nav-link {
    color: white !important;
}

a {
    text-decoration: none !important;
}

.social-link {
    color: white;
    padding: 10px;
}

.nav-links.active {
    color: #0E8515 !important;
    font-weight: 500;
}

.nav-links.active::after {
    width: 100%;
    transition: width .3s;
}