@import "../../common/variables.scss";

.recent-blog-title {

    :nth-child(1) {
        color: $primary;
        font-weight: 700;
        font-size: 40px;
        border-bottom: 5px solid $secondary;
    }

    p {
        margin: 2rem 0rem !important;
        font-size: 22px;
        font-weight: 400;
    }
}

.client-slider-wrapper {
    min-height: 350px;

    @media (max-width:992px) {
        min-height: 450px;
    }
}


.client-slider {
    margin: 0;
}

.client-slider .slick-slide {
    padding-left: 20px;
}


.client-card {
    text-align: center;
    background-color: $primary_light;
    padding: 25px;
    position: relative;
    transition: all 1s;
    min-height: 200px;

    @media (min-width:768px) {
        display: flex;
        align-items: center;
    }

    &:hover {
        transition: all 1s;
        box-shadow: 1px 10px 35.2px rgba(0, 0, 0, 0.25);
    }

    img {
        z-index: 2;
        position: relative;
    }

    .client-info {
        margin-top: 30px;

        @media (min-width:768px) {
            margin-top: 0;
            position: absolute;
            display: flex;
            gap: 20px;
            left: 50%;
            bottom: -50%;
            transform: translate(-50%, -0%);
            align-items: end;
        }
    }

    .client-image {
        display: inline-block;
        border: 2px solid $primary;
        border-radius: 100%;

        img {
            width: 60px;
            height: 60px;
            border-radius: 100%;
        }
    }

    .client-name,
    .client-designation {
        line-height: normal;
        font-style: normal;
        font-size: 22px;
    }

    .client-name {
        color: $primary;
        font-weight: 700;
    }

    .client-designation {
        color: #000;
        font-weight: 500;
    }
}