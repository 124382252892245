@import "../../common/variables.scss";

.work-history-title {
    padding: 2rem 0rem;

    :nth-child(1) {
        color: $primary;
        font-weight: 700;
        font-size: 40px;
        border-bottom: 5px solid $secondary;
    }

    p {
        margin: 2rem 0rem !important;
        font-size: 22px;
        font-weight: 400;
    }
}

.category-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: none !important;
    gap: 10px;

    .category-link {
        border-radius: 0 !important;
        padding: 8px 30px;
        font-weight: 600;
        color: $primary !important;
        border: 2px solid $primary;

        &:hover {
            background-color: $primary !important;
            color: white !important;
        }
    }
}

.tab-pane {
    .card {
        border-radius: 0%;
        border: none;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin: 2rem 0rem;

        .card-image {
            height: 300px;

            img {
                width: 100%;
                height: 100% !important;
                object-fit: cover;
            }
        }

        .card-body {
            border-top: 3px solid $primary;
            position: relative;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            align-self: normal;
            background-color: $primary_light;
            transition: .5s;
            min-height: 280px;

            .history_icon {
                border-radius: 30%;
                font-size: 4rem;
                background-color: $primary;
                padding: 10px;
                border-radius: 50%;
                transition: .5s;
                color: white;
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
                top: -20%;
            }
        }

        &:hover {
            .card-body {
                background-color: $primary;
                color: white;

                .history_icon {
                    background-color: white;
                    color: $primary;
                }
            }
        }
    }
}

.category-tabs .category-link.active {
    background-color: $primary !important;
    color: white !important;
}

.history_icon {
    margin-top: 30px;
}