@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.box-container {
    padding: 0px 40px !important;
    font-family: 'Poppins', sans-serif;

    @media (max-width:425px) {
        padding: 20px !important;
    }
}

h1,
h2,
h3,
.section_title,
.work-history-title,
.recent-blog-title,
.founder-title {
    font-family: "DM Serif Display", serif !important;
    font-weight: 400;
    font-style: normal;
}

section {
    padding: 40px 0;
}

.mb-100 {
    margin-bottom: 100px;

    @media (max-width:992px) {
        margin-bottom: 40px;
    }
}


.text-justify {
    text-align: justify !important;
}

.blog-banner-image {
    max-height: 450px;
    object-fit: cover;
}

.blog-container {
    @media (min-width:992px) {
        width: 60%;
        margin: auto;
    }

    padding: 40px 20px;
}

.btn-unstyle {
    background: none !important;
    border: none !important;
}

.box-banner-image {
    max-height: 450px;
    object-fit: cover;
}