.blog-card {
    margin-bottom: 25px;
}

.blog-card:hover {
    cursor: pointer;
}

.blog-card:hover .section_title {
    text-decoration: underline;
}

.blog-image {
    max-height: 180px;
}

.blog-description {
    text-align: justify;
}