@import "../../common/variables.scss";

.banner {
    height: 100% !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;

    .banner-sub-heading {
        color: $secondary;
    }

    h1 {
        color: white;
        font-weight: bold;
    }

    a {
        border: none;
        color: white;
        padding: 0px 20px;
        background-color: $primary;
        transition: .1s;
        font-size: 35px !important;

        &:hover {
            background-color: $secondary;
            transition: .1s;
        }
    }
}

@media (min-width:768px) {
    .w-md-75 {
        width: 75%;
    }
}

.banner-image {
    min-height: calc(100vh - 150px);
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    background-size: cover !important;
    background-position: center center !important;

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(#0354034d, rgba(0, 0, 0, 0.866));
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
    }
}

.banner-section {
    position: relative;

    .slick-dots {
        bottom: 40px !important;
    }

    .slick-dots li button {
        background-color: gray !important;
        width: 10px !important;
        height: 10px !important;
        border-radius: 20px;
    }

    .slick-dots li.slick-active button {
        background-color: white !important;
    }
}