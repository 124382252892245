@import "../../common/variables.scss";

.recent-blog-title {
    // padding-bottom: 2rem;

    :nth-child(1) {
        color: $primary;
        font-weight: 700;
        font-size: 40px;
        border-bottom: 5px solid $secondary;
    }

    p {
        margin: 2rem 0rem !important;
        font-size: 22px;
        font-weight: 400;
    }
}

.recent-blog-card-box-container {
    .card {
        border-radius: 0%;
        border: none !important;
        margin: 1rem 0rem;
        transition: .2s !important;

        .card-image {
            border: 3px solid $primary;

            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
            }
        }

        .card-body {
            position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap !important;
            flex-direction: column;
            align-self: normal;
            transition: .5s;

            :nth-child(1) {
                color: black;
                font-size: 20px;
                font-weight: 700;
            }
        }

        &:hover {
            transition: .2s !important;

            .card-body {
                :nth-child(1) {
                    color: $secondary;
                    font-size: 20px;
                    font-weight: 700;
                }

                :nth-child(2) {
                    color: $primary;
                }
            }
        }
    }
}