@import "../../common/variables.scss";

.brand-container {
    background-color: $primary_light;

    img {
        width: 200px !important;
        aspect-ratio: 3/2;
        object-fit: contain;
        mix-blend-mode: multiply;
        @media (max-width:992px) {
            width: 120px !important;
        }
    }

    .slick-slide {
        background-color: $primary_light;
    }
}