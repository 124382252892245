@import "../../common/variables.scss";

footer {
    background: url('../../assets/images/footer.png');
    min-height: 590px;
    object-fit: cover;
    position: relative;
    padding: 20px;

    @media (max-width:992px) {
        text-align: center;
    }
}

.footer-wrapper {
    min-height: 590px;
}

.form-input {
    border: none;
    outline: none;
    display: block;
    color: black;
    background-color: #feffff6f;
    min-height: 51px;
    margin: 20px 0;
    width: 100%;
    padding: 20px;
    font-weight: bold;

    &::placeholder {
        color: white;
        font-weight: lighter;
    }
}

.form-button-wrapper {
    position: relative;

    .form-button {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
}

.form-button {
    height: 36.53px;
    min-width: 134.78px;
    font-size: 20px;
    background-color: $primary;
    border: 2px solid white;
    color: white;
}

.footer-link {
    text-decoration: none;
    color: #FFF;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover {
        color: black;
    }
}

.footer-link-group {
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-bottom: 2px solid white;
    padding-bottom: 10px;
    @media (max-width:992px) {
        justify-content: center;
        margin-bottom: 20px;
    }

    .footer-link {
        font-size: 20px;
    }
}

.footer-icon-info-group {
    @media (min-width:992px) {
        background-color: $secondary;
        padding: 20px;
        margin-bottom: 20px;
        position: absolute;
        top: -90px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
}

.footer-info-icon {
    @media (max-width:992px) {
        background-color: $secondary;
        margin: 20px 0;
        text-align: center;
        border: 1px solid;
        padding: 20px;

        img {
            width: 58px;
            margin-bottom: 20px;
        }
    }
}

.ct-link {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin: 20px;
    color: white;
    box-shadow: 0 0 10px lightgray;
    background-color: black;
    border: 3px solid green;
    &:hover {
        background-color: green;
    }
}
.ct-link.two {
    bottom: 60px;
}